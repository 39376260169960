import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import PageActions from "../../../base/PageActions";
import ContentBox from "../../../../components/content-box/ContentBox";
import '../../../styles/Common.scss';
import './PatchNotesPage.scss';

interface PatchNotesPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface PatchNotesPageState {
    versionList: string[],
    version: string
}

const PatchNotesPage = (props: PatchNotesPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Patch Notes - Chase the Fox`,
        description: `The up-to-date patch notes for Chase's website! Come find out what has changed!`,
        keywords: ["patch notes", "updates", "website updates", "api updates", "website changes"],
        imgSrc: `chase/assets/chase_full.png`,
        url: `patch-notes`
    }), [props]);

    const [state, setState] = useState<PatchNotesPageState>({
        versionList: [],
        version: ''
    });

    const [params, setParams] = useSearchParams();

    const getVersionList = useCallback(() => {
        let versions: string[] = [];
        let elements = document.getElementsByTagName('div');
        for (let i = 0; i < elements.length; i++) {
            let element: HTMLDivElement = elements.item(i)!;
            if (element.id && element.id.startsWith('version-')) {
                let versionNumber = element.id.substring(8).replace(/-/g, '.');
                versions.push(versionNumber);
            }
        }

        return versions;
    }, [])

    useEffect(() => {
        let versionList: string[] = getVersionList();
        let version: string = params.get('version') || '';
        if (!version.length || !versionList.includes(version)) {
            setParams({}, {replace: true});
            version = versionList[0];
        }
        setState({versionList, version});
    }, [getVersionList, params, setParams]);

    const changeVersion = (value: string): void => {
        setState({...state, version: value});
        setParams({version: value}, {replace: true});
    }

    return page.generateMetaContent(
        (<>
            <ContentBox header="Patch Notes">
                <div className="center">
                    <label htmlFor="patch-select">Select a version: </label>
                    <select id="patch-select" onChange={(event) =>
                        changeVersion(event.target.value)} value={state.version}
                    >
                        {state.versionList.map(version =>
                            <option key={`patch-option-${version.replace(/\./g, '-')}`}
                                    value={version}
                            >
                                {version}
                            </option>)
                        }
                    </select>
                </div>
                <div id="version-0-11-1" hidden={state.version !== '0.11.1'}>
                    <h3 className="headline">Version 0.11</h3>
                    <ul className="separated-list-elements">
                        <li>Added a missing image on the languages home page</li>
                        <li>Fixed an issue with the donation tables not updating correctly when changing year</li>
                        <li>Fixed display issues with the donation tables on mobile</li>
                        <li>Fixed an issue with social media cards working incorrectly for world pages</li>
                    </ul>
                </div>
                <div id="version-0-11" hidden={state.version !== '0.11'}>
                    <h3 className="headline">Version 0.11</h3>
                    <ul className="separated-list-elements">
                        <li>The character page has been split between protagonists and antagonists</li>
                        <li>
                            Added a language list to the world section, including an initial look at Casarokan Common
                        </li>
                        <li>Updated the Gamers Outreach charity page</li>
                        <li>Fixed an issue with navigation when moving away from a character or world page</li>
                        <li>Fixed an issue with the mobile navigation menu not disappearing correctly in some cases</li>
                        <li>Fixed a visual bug with the breadcrumbs at the bottom of world pages on mobile</li>
                        <li>Fixed the styling on the main page of the charities section under streaming</li>
                        <li><strong>Fursona and Transformation Generator</strong>
                            <ul>
                                <li>Added buttons for sharing your result to Bluesky and Mastodon</li>
                                <li>Fixed an issue in which the fursona generator will always say "mythical" when
                                    sharing a result
                                </li>
                                <li>Fixed an issue in which previewing a color in the fursona generator sometimes
                                    blocks the link to return to the previous page
                                </li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Updated framework to prepare for future enhancements</li>
                                <li>Security fixes and updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-10" hidden={state.version !== '0.10'}>
                    <h3 className="headline">Version 0.10</h3>
                    <ul className="separated-list-elements">
                    <li>Many of the mechanics have been overhauled for ease of development and fixing bugs.
                            Of note:
                            <ul>
                                <li>Fixed an issue in which some pages refreshed certain elements multiple times</li>
                                <li>Fixed an issue in which some pages would not behave correctly when using the back
                                    and forward buttons on the browser</li>
                                <li>Fixed an issue in which the version always displayed in the address for patch
                                    notes</li>
                                <li>The shop page can now be updated more easily, so it should have the latest info
                                    more readily available</li>
                                <li>Updated text in some areas of the site to be more concise</li>
                            </ul>
                        </li>
                        <li><strong>Fursona and Transformation Generator</strong>
                            <ul>
                                <li>Reduced the animation time when showing results</li>
                            </ul>
                        </li>
                        <li>Added information for the Kokua Ka 'Alopeke charity event</li>
                        <li>Updated rules for the Casarokan Gym Challenge</li>
                        <li>Updated copyright information</li>
                        <li>Security fixes and updates</li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Added a new API to fetch shop featured items</li>
                                <li>Security fixes and updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-9-4" hidden={state.version !== '0.9.4'}>
                    <h3 className="headline">Version 0.9.4</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Fursona and Transformation Generator</strong>
                            <ul>
                                <li>The Random Transformation Generator has been split into two distinct
                                    entities!
                                    <ul>
                                        <li>The Fursona Generator uses the entire list of species outside of
                                            Casarokan unique ones. Color and distinct modifier selections are
                                            available here.
                                        </li>
                                        <li>The Transformation Generator uses the same logic except for colors.
                                            This was a feature that did not see much use and is more suited to an
                                            artistic-themed generator as above.
                                        </li>
                                        <li>Adjusted the categories of two existing species entries</li>
                                        <li>Added a few new entries for the fursona generator</li>
                                        <li>Note: More work will be done in the future to differentiate these two
                                            interfaces from one another.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Updated the social media buttons</li>
                        <li>Updated the QR code landing page</li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Fixed a minor caching issue</li>
                                <li>Security fixes and updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-9-3" hidden={state.version !== '0.9.3'}>
                    <h3 className="headline">Version 0.9.3</h3>
                    <ul className="separated-list-elements">
                        <li>Updated the redeems list for SAR streams</li>
                    </ul>
                </div>
                <div id="version-0-9-2" hidden={state.version !== '0.9.2'}>
                    <h3 className="headline">Version 0.9.2</h3>
                    <ul className="separated-list-elements">
                        <li>Updated the comic section to have a dropdown for selecting a chapter</li>
                        <li>Updated the comic view so it is faster at pulling page images</li>
                        <li>Updated the comic view mechanic for automatically releasing new pages</li>
                        <li>Updated some images for Katrina and Malichi</li>
                        <li>Fixed a general issue with table displays</li>
                        <li>Fixed page titles on the comic pages</li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Deprecated some APIs that are no longer in use</li>
                                <li>Changed caching mechanism to improve speed and correctness of site pages</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-9-1" hidden={state.version !== '0.9.1'}>
                    <h3 className="headline">Version 0.9.1</h3>
                    <ul className="separated-list-elements">
                        <li>Added a page for redeemable character changes in SAR</li>
                        <li>Added a new logo for the Casarokan Gym Challenge</li>
                        <li>Added new rules to the gym challenge page</li>
                        <li>Changed the display of badges for those who did not complete a gym</li>
                    </ul>
                </div>
                <div id="version-0-9" hidden={state.version !== '0.9'}>
                    <h3 className="headline">Version 0.9</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Improved site navigation</strong>
                            <ul>
                                <li>Re-categorized all navigation elements</li>
                                <li>On mobile, subcategories can be selected after tapping a main menu element</li>
                                <li>On desktop, hovering over a main menu element will display the subcategories</li>
                                <li>All Tale Chasers related info (comic, characters, and world) have been
                                    consolidated into a Story section</li>
                                <li>A new section has been added to navigation containing stream information</li>
                                <li>All art-related pages have been moved to a new Art section</li>
                                <li>A shop page has been added under the Art section (more info below)</li>
                                <li>Other pages have been placed under the Fun and Site sections</li>
                                <li>A new section has been added to navigation for "Otherworlds" (more info below)</li>
                            </ul>
                        </li>
                        <li><strong>The Caudalglen Guild of Adventurers</strong>
                            <ul>
                                <li>The secret group teased in patch 0.7 is now on the main menu under the
                                    Otherworlds section</li>
                                <li>The makings of another world are set to tell a more serious tale</li>
                                <li>Set in a different land with a high fantasy setting, new character introductions
                                    are available with more information coming</li>
                            </ul>
                        </li>
                        <li><strong>Shop Section Added</strong>
                            <ul>
                                <li>A constant link to my RedBubble page is available here!</li>
                                <li>Featured and recent items are on display here</li>
                            </ul>
                        </li>
                        <li><strong>New Dark Mode Theme</strong>
                            <ul>
                                <li>A darker color palette can be used on the site now</li>
                                <li>Theme selection should be initialized based on browser preference</li>
                                <li>Once set it is remembered until you toggle it again or clear your cache</li>
                                <li>A toggle in the upper right corner of the header allows you to switch back
                                    and forth</li>
                            </ul>
                        </li>
                        <li><strong>Random Transformation Generator</strong>
                            <ul>
                                <li>Fixed some minor graphical errors</li>
                            </ul>
                        </li>
                        <li>A new endpoint <a href="https://stream.chasethefox.com"
                                              target="_blank"
                                              rel="noopener noreferrer">stream.chasethefox.com</a> has been added
                            to provide a consistent link to the channel.
                        </li>
                        <li>All images have been moved to a new endpoint for ease of access and management.</li>
                        <li>Some image metadata has been updated.</li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Removed some extraneous APIs and functionality based on the CDN update</li>
                                <li>Security fixes and updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-8-2" hidden={state.version !== '0.8.2'}>
                    <h3 className="headline">Version 0.8.2</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Random Transformation Generator</strong>
                            <ul>
                                <li>
                                    Added modifier selection
                                    <ul>
                                        <li>The option is now available to choose whether a modifier will be added
                                            to the requested transformation</li>
                                        <li>Options include different sizes, shapes, elements, and more!</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-8-1" hidden={state.version !== '0.8.1'}>
                    <h3 className="headline">Version 0.8.1</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Random Transformation Generator</strong>
                            <ul>
                                <li>
                                    Added hover/tap functionality for colors
                                    <ul>
                                        <li>On desktop, hovering over a color will show a swatch with a color
                                            preview and a hex code for the color
                                        </li>
                                        <li>On mobile, tapping the name of a color will show its swatch in the
                                            middle of the screen
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Added color set selection
                                    <ul>
                                        <li>A few basic color sets are provided and more will be added later</li>
                                        <li>These color sets will limit the types of colors returned</li>
                                        <li>Additional information on the color set is provided when selecting it</li>
                                    </ul>
                                </li>
                                <li>Fixed appearance on mobile</li>
                                <li>Fixed an issue in which the remote server was not returning the correct hex code</li>
                                <li>Fixed an issue in which unexpected input would cause a server error</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-8" hidden={state.version !== '0.8'}>
                    <h3 className="headline">Version 0.8</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Random Transformation Generator</strong>
                            <ul>
                                <li>
                                    Added the option to select a number of random colors to include
                                    <ul>
                                        <li>Note: Future features are planned that will allow you to pick
                                            categories of colors, pick smart combinations of colors, and have
                                            the option to pick complex compositions of colors
                                        </li>
                                    </ul>
                                </li>
                                <li>Removed a button on the form page to simplify it</li>
                                <li>Removed restriction on number of fields that can be set</li>
                                <li>Updated the text and positions of the cancel and submit buttons</li>
                                <li>Updated some SEO information for when the page is linked on social media</li>
                            </ul>
                        </li>
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed an issue in which commission image links were broken</li>
                                <li>Fixed an issue in which comic commentary speech bubble was too small for text
                                    at times</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Fixed some issues with internal caching of elements</li>
                                <li>Security fixes and updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-7-1" hidden={state.version !== '0.7.1'}>
                    <h3 className="headline">Version 0.7.1</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed some issues with sitemap fetching</li>
                                <li>Adjusted some of the preview data (SEO) for the world section</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Added some APIs for fetching donation info</li>
                                <li>Fixed some issues with sitemap generation</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-7" hidden={state.version !== '0.7'}>
                    <h3 className="headline">Version 0.7</h3>
                    <ul className="separated-list-elements">
                        <li><strong>A mysterious group is lurking...</strong>
                            <ul>
                                <li>Can you find them?</li>
                            </ul>
                        </li>
                        <li><strong>The Shop is Open!</strong>
                            <ul>
                                <li>A Redbubble shop with some of my art designs is now available (link on the home page)</li>
                            </ul>
                        </li>
                        <li><strong>Random Transformation Generator</strong>
                            <ul>
                                <li>Fixed an issue in which links for Casarokan unique creatures would redirect to the wrong place</li>
                            </ul>
                        </li>
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Changed the navigation menu and site theme to transition between pages more smoothly</li>
                                <li>
                                    Updated media buttons
                                    <ul>
                                        <li>YouTube button now allows for handles</li>
                                        <li>Mastodon button can now be configured with different servers</li>
                                    </ul>
                                </li>
                                <li>Fixed an issue in which the name of the comic was misrepresented in the tab</li>
                                <li>Fixed an issue in which media buttons were stretching the screen on mobile devices</li>
                                <li>Fixed an issue in which media buttons would appear outside their bounding box on narrower desktop screens</li>
                                <li>Fixed an issue in which font size was larger than expected for narrower desktop screens</li>
                                <li>Fixed the alignment of the mobile navigation menu</li>
                                <li>The "Return to Top" button now displays a pointer cursor when hovering over it on desktop</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Added a new API for pulling characters from different settings</li>
                                <li>Fixed some issues that prevented proper logging of errors</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-6-2" hidden={state.version !== '0.6.2'}>
                    <h3 className="headline">Version 0.6.2</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Random Transformation Generator</strong>
                            <ul>
                                <li>Added buttons for quick access</li>
                                <li>Added a link to display the full list of options</li>
                                <li>All options are now cached for quicker, easier retrieval</li>
                            </ul>
                        </li>
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed the styling for the social media buttons on the home page so they
                                    display in their bounding box correctly even when the screen is shrunk</li>
                                <li>Fixed a bug in which switching between desktop and mobile on the same device
                                    could cause the navigation menu to disappear</li>
                                <li>Added Home button to navigation menu</li>
                                <li>Replaced footer navigation menu with "Return to Top" button</li>
                                <li>Reduced the size of the site title on mobile</li>
                                <li>Added a link to the latest comic page in the Tale Chasers section</li>
                                <li>Updated some text on various pages</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Fixed various background issues for administration purposes</li>
                                <li>Security updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-6-1" hidden={state.version !== '0.6.1'}>
                    <h3 className="headline">Version 0.6.1</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Changed the size of comic pages on mobile to make them more readable</li>
                                <li>Moved and reorganized social media buttons to intro container on the home page</li>
                                <li>Reorganized the QR code landing page</li>
                                <li>
                                    Added a redirect for the latest comic page
                                    <ul><li>(Note: You can now go to "comics/talechasers/latest" to always jump to the most recent page)</li></ul>
                                </li>
                                <li>
                                    Renamed location of the TF generator and fan art sections
                                    <ul><li>(Note: The old names will still redirect to the new locations)</li></ul>
                                </li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Updated sitemap for new web changes</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-6" hidden={state.version !== '0.6'}>
                    <h3 className="headline">Version 0.6</h3>
                    <ul className="separated-list-elements">
                        <li><strong>New Features</strong>
                            <ul>
                                <li>The Random Transformation Generator is live with 300 total possible options!</li>
                                <li>A fan art submission page is now available if you'd like your art featured on
                                    the website, stream, or other media!
                                    <ul><li>(Note: Both can be found under Extras)</li></ul>
                                </li>
                            </ul>
                        </li><li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed an issue in which Twitter was not displaying website images</li>
                                <li>Moved social media buttons on home page below intro
                                    <ul><li>(Note: A separate page has been created for those who scan my QR badge)</li></ul>
                                </li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Reorganized return codes to help distinguish client and server errors</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-5-6" hidden={state.version !== '0.5.6'}>
                    <h3 className="headline">Version 0.5.6</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed an issue in which an empty comic page would show when the page is not yet released</li>
                                <li>Added images to the extras and patch notes pages</li>
                                <li>Updated existing images on the error and 404 pages</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Updated logic for communicating released comic pages to avoid cache staling</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-5-5" hidden={state.version !== '0.5.5'}>
                    <h3 className="headline">Version 0.5.5</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed social media buttons on mobile so they are easier to identify and click</li>
                                <li>Fixed styling problems on mobile that caused gaps to appear on some components</li>
                                <li>Fixed issue with RSS feed not showing</li>
                                <li>Comic status now displayed on home page</li>
                                <li>Reduced height of speech bubbles</li>
                                <li>Changed the display of links when hovering over them</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Fixed issues for automatic comic page releases</li>
                                <li>Security updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-5-4" hidden={state.version !== '0.5.4'}>
                    <h3 className="headline">Version 0.5.4</h3>
                    <ul className="separated-list-elements">
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Fixed a caching issue that was preventing pages from loading properly</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-5-3" hidden={state.version !== '0.5.3'}>
                    <h3 className="headline">Version 0.5.3</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Changed the placement and display of media buttons on the home page</li>
                                <li>Changed patch notes page so only selected patch is displayed</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Updated sitemap generation to match the new layout</li>
                                <li>Improved caching to allow site content to load faster</li>
                                <li>Security updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-5-2" hidden={state.version !== '0.5.2'}>
                    <h3 className="headline">Version 0.5.2</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed an issue on the home page in which commission status check may not occur</li>
                                <li>Fixed an issue in which certain pages would not load properly on mobile</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-5-1"  hidden={state.version !== '0.5.1'}>
                    <h3 className="headline">Version 0.5.1</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Added Telegram stickers page</li>
                                <li>Updated Twitter card info for world sections</li>
                                <li>Fixed an issue in which world section images loaded at their largest resolution</li>
                                <li>Fixed some broken redirect links</li>
                                <li>Fixed some issues with SEO display for most pages</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-5"  hidden={state.version !== '0.5'}>
                    <h3 className="headline">Version 0.5</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Website framework has been changed</li>
                                <li>Home page has been re-designed</li>
                                <li>Commission form link is now only available when commission status is not "CLOSED"</li>
                                <li>Comic page turning now requires navigation buttons (back will now return to the comic home page)</li>
                                <li>QRI Badge testing has been removed due to lack of interest</li>
                                <li>Social media cards have been changed and should load correctly now</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>All APIs are now accessible through chasethefox.com (api.chasethefox.com is now deprecated!)</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-4-1" hidden={state.version !== '0.4.1'}>
                    <h3 className="headline">Version 0.4.1</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Removed Picarto banner</li>
                                <li>Added Twitch channel link</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-4" hidden={state.version !== '0.4'}>
                    <h3 className="headline">Version 0.4</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Changed banner image to use more recent artwork</li>
                                <li>Comic pages will now be automatically viewable on their given release date</li>
                                <li>Added commentary to comic pages</li>
                                <li>Fixed issue with scrolling on comic pages</li>
                                <li>Commission prices and form have been updated</li>
                                <li>Improved speed of commissions page</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Optimized database, RSS feed, and logging features</li>
                                <li>Security updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-3-3" hidden={state.version !== '0.3.3'}>
                    <h3 className="headline">Version 0.3.3</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Added news banner to home page</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-3-2" hidden={state.version !== '0.3.2'}>
                    <h3 className="headline">Version 0.3.2</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed a bug in which SEO tags would point to the wrong canonical site</li>
                                <li>Added Twitter meta tags</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Fixed a bug in which the wrong HTTP response code was used for world images</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-3-1" hidden={state.version !== '0.3.1'}>
                    <h3 className="headline">Version 0.3.1</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Fixed a bug in which the image would not load for the Extras Twitter card</li>
                                <li>Fixed display issues for cards in Comic, Character, and World sections</li>
                                <li>Fixed display issue when loading new comic pages</li>
                                <li>Fixed a console error with the Twitter widget (really this time)</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Fixed a bug in which new missing fields for world data would cause an error</li>
                                <li>Fixed a bug in which world section images were not being cached</li>
                                <li>Fixed a bug in which the sitemap would not properly display all pages</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-3" hidden={state.version !== '0.3'}>
                    <h3 className="headline">Version 0.3</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Updated search engine optimization tools to be more robust</li>
                                <li>Fixed a graphical issue on desktop in which the Twitter feed container would
                                    expand on load
                                </li>
                                <li>Fixed a styling issue with speech bubbles</li>
                                <li>Fixed the header so the site name also acts as a link to the home page</li>
                                <li>Fixed a console error with the Twitter widget</li>
                                <li>Security updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-2-4" hidden={state.version !== '0.2.4'}>
                    <h3 className="headline">Version 0.2.4</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Added tags for search engine optimization</li>
                                <li>Improved load speed for world section pages</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Security updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-2-3" hidden={state.version !== '0.2.3'}>
                    <h3 className="headline">Version 0.2.3</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Improved page load speed</li>
                                <li>Improved comic image load speed</li>
                                <li>Fixed issue with white space appearing at the bottom of smaller pages</li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Fixed issues with caching</li>
                                <li>Security updates</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div id="version-0-2" hidden={state.version !== '0.2'}>
                    <h3 className="headline">Version 0.2</h3>
                    <ul className="separated-list-elements">
                        <li><strong>Website Updates</strong>
                            <ul>
                                <li>Added this patch notes section!</li>
                                <li>Fixed images in the character and world section shifting as they load</li>
                                <li>Fixed issue with minimum height on world bestiary section</li>
                                <li>Changed padding in comic select section</li>
                                <li>Changed image size on character and world bestiary subsections for smaller
                                    mobile devices
                                </li>
                            </ul>
                        </li>
                        <li><strong>API Updates</strong>
                            <ul>
                                <li>Added automatic sitemap generation</li>
                                <li>Added image caching for fetching images</li>
                                <li>Added 'size' query option for images:
                                    <ul>
                                        <li>small: 10% original size</li>
                                        <li>medium: 25% original size</li>
                                        <li>large: 50% original size</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <img className="character-bottom"
                     alt="Malichi and Wally working at a laptop"
                     title="We're working hard on more patches!"
                     src={page.image().getCdnImageSrc(`chase/assets/patch_notes.png`)}/>
            </ContentBox>
        </>
    ));
}

export default PatchNotesPage;