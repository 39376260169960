import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ContentBox from "../../../../../components/content-box/ContentBox";
import DonationTracker from "../../../../../components/donation-tracker/DonationTracker";
import DonationInfo from "../../../../../model/DonationInfo";
import PageActions from "../../../../base/PageActions";
import "../../../../styles/Common.scss";

interface AlsCharityStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const AlsCharityStreamPage = (props: AlsCharityStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Charities: ALS - Chase the Fox`,
        description: `Learn more about Chase's efforts to help fight ALS!`,
        keywords: ["twitch", "stream info", "chasetheredfox", "charity", "als united"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/charities/als`
    }), [props]);

    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const result = useRef<DonationInfo>();

    const navigateTo = useNavigate();

    useEffect(() => {
        page.http().getDonations("als", "2023")
            .then(response => {
                if (!response) {
                    navigateTo("/error", {replace: false});
                } else {
                    result.current = response;
                }
            })
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchedResult(true));
    }, []);

    return (
        <>
            <ContentBox header="ALS claims all the lives it touches. We're here to stop it!">
                <div>
                    <img src={page.image().getCdnImageSrc('chase/stream/charities/als_group_photo.png')}
                         alt="Team Dogbomb at the Triangle Walk to Fight ALS in 2022"
                         title="Team Dogbomb braving the heat!"
                         className="width-50-percent block"/>
                    <p>
                        ALS, also known as amyotrophic lateral sclerosis or Lou Gehrig's Disease, is a horrible
                        affliction. It slowly deteriorates the nervous system, robbing one of their muscle strength
                        in various parts of their body over the course of years. It can affect anyone, and it is
                        100% lethal. The ALS Association continues to raise money for ALS treatments in hopes of
                        one day finding a cure.
                    </p>
                    <p>
                        Every year, I walk along with <strong>Team Dogbomb</strong> and my local ALS Association
                        chapter to help raise money for a cure and Unlock ALS. Team Dogbomb was founded after
                        furry fandom member and marathon runner Tony "Dogbomb" Barrett came down with the disease
                        and passed away a few years after. We raise money in his name. You may see me roaming
                        around with my Unlock ALS shirt once in a while.
                    </p>
                    <p>
                        If you are able, please
                        consider <a href="https://donate.als.org/give/287064/#!/donation/checkout?c_src=chasethefox.com"
                                    target="_blank"
                                    rel="noopener noreferrer">
                            donating to the ALS Association
                        </a> or to your local chapter.
                    </p>
                    {hasFetchedResult &&
                        <DonationTracker key={`donation-tracker-als-${Object.keys(result.current!).length}`}
                                         cause="ALS Triangle Walk 2023"
                                         donations={result.current!}/>
                    }
                </div>
            </ContentBox>
        </>
    );
}

export default AlsCharityStreamPage;