import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import PageActions from "../../../base/PageActions";
import ShopEntry from "../../../../model/ShopEntry";
import "../../../styles/Common.scss";
import "./ShopPage.scss";

interface ShopPageProps {
    dependencies: Dependencies,
    env: Environment
}

const ShopPage = (props: ShopPageProps) => {

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Shop - Chase the Fox`,
        description: `Fox Prime Studios, Chase's self-run business, has lots of goodies available!`,
        keywords: ["merch", "furry art", "chasetheredfox", "shop", "fox prime studios"],
        imgSrc: `chase/assets/fps_logo.png`,
        url: `shop`
    });

    const [fetchComplete, setFetchComplete] = useState<boolean>(false);

    const entries = useRef<ShopEntry[]>([]);

    const navigateTo = useNavigate();

    useEffect(() => {
        page.http().getShopEntries()
            .then(data => entries.current = data)
            .catch(() => navigateTo("/error", {replace: false}))
            .finally(() => setFetchComplete(true));
    }, []);

    return page.generateMetaContent(
        <>
            <ContentBox header="Check out the shop! Can I interest you in some art?">
                <div>
                    <img src={page.image().getCdnImageSrc('chase/assets/fps_logo.png')}
                         alt="A fox head constructed from equilateral triangles, the logo for Fox Prime Studios"
                         title="Fox Prime Studios"
                         className="fps-logo"/>
                    <p>
                        I run a business on the side called Fox Prime Studios. It's a small business currently, but
                        perhaps one day it will become something greater! The shop features a multitude of
                        merchandise based on my art, and you can find it on
                        RedBubble! <a href="https://www.redbubble.com/people/foxprimestudios/shop"
                                      target="_blank"
                                      rel="noopener noreferrer">
                            Click here to go directly to the RedBubble site
                        </a> or check out the featured items below for more information!
                    </p>
                    <h3>Featured Items</h3>
                    {fetchComplete && entries.current!.map(entry =>
                        <div className="shop-item">
                            <p className="center"><strong>
                                {entry.title}
                            </strong></p>
                            <img src={page.image().getCdnImageSrc(`chase/shop/${entry.name}.png`)}
                                 alt={entry.name}
                                 title={entry.title}
                                 className="width-50-percent block"/>
                            <p>{page.html().parse(entry.description)}</p>
                            <a href={entry.link}
                               target="_blank"
                               rel="noopener noreferrer">
                                Direct link here!
                            </a>
                        </div>)
                    }
                </div>
            </ContentBox>
        </>
    );
}

export default ShopPage;